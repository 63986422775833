export type BaseDocument = {
  id: string;
  createdTime: Date;
  updatedTime: Date;
};

export type User = BaseDocument & {
  email: string;
  photoURL: string;
  ownerFullName: string;
  virtualNumbers: string[];
};

export type CallSummaryDailyAgregation = BaseDocument & {
  ANSWER: number;
  NOANSWER: number;
  BUSY: number;
  CANCEL: number;
  IVR_HANGUP: number;
  CONGESTION: number;
};

export type Organization = BaseDocument & {
  admins: string[];
  users: string[];
  logo: string;
  name: string;
};

export type BaseContact = BaseDocument & {
  contactOrigin: string;
  isInSpamDatabase: boolean;
  lastBulkMessageReceivedTime: string;
  _metadata: any;
};

export type GenericContact = BaseContact & {
  callerPhoneNumber: string;
  callerFirstName: string;
  callerLastName: string;
  additionalNotes: string;
  optedOutOfBulkMessages: boolean;
};

export type RealEstateContact = GenericContact & {
  customerType: CustomerType | null;
  customerBudget: number;
  neighborhood: string;
  street: string;
  roomsMin: Room | null;
  roomsMax: Room | null;
  customerSeriousness: CustomerSeriousness | null;
};

/**
 * Customer seriousness:
 * 1. לא רציני = Not serious
 * 2. רציני = Serious
 * 3. אין התאמה = No match
 */
export const customerSeriousness = ["לא רציני", "רציני", "אין התאמה"] as const;
export type CustomerSeriousness = typeof customerSeriousness[number];

export const rooms = [0, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6] as const;
export type Room = typeof rooms[number];

/**
 * Customer types:
 * 1. מוכר = Seller
 * 2. קונה = Buyer
 * 3. שוכר = Tenant
 * 4. משכיר = Landlord
 * 5. מתווך = Realtor
 * 6. עו"ד = Attorney
 * 7. שמאי = Appraiser
 */
export const customerTypes = ["מוכר", "קונה", "שוכר", "משכיר", "מתווך", 'עו"ד', "שמאי"] as const;
export type CustomerType = typeof customerTypes[number];

export const callEndResults = [
  "ANSWER",
  "NOANSWER",
  "BUSY",
  "CANCEL",
  "IVR_HANGUP",
  "CONGESTION"
] as const;
export type CallEndResult = typeof callEndResults[number];

export type CallSummary = BaseDocument & {
  callerNumber: string; // local
  callerNumberInternational: string;
  callerNumberWaFormat: string;
  customerName: string;
  duration: string;
  startAt: string;
  endAt: string;
  groupName: string;
  virtualNumber: string; // local
  realNumber: string; // local
  recordingLink: string;
  recordingLinkDirect: string;
  result: CallEndResult;
  status: string;
};

export type PayCallSettings = BaseDocument & {
  customerIntroName: string;
  customerOrganizationName: string;
  customerUserName: string;
  noAnswerWhatsappMessage: string;
  noPhoneReplyCallToAction: string;
  noResponseBotWhatsappForwardingNumber: string;
  notesAboutCustomer: string; // internal
  number: string; // local virtual number
  ownerMails: string[];
};

export type ProcessedListingAddress = {
  city: string;
  neighborhood: string;
  street: string;
  houseNumber: number;
  floor: number;
  coords?: {
    lat: number;
    lon: number;
  };
};

export type ProcessedListingData = Partial<BaseDocument> & {
  address: ProcessedListingAddress;
  buildingTotalFloors?: number;
  agencyName?: string;
  sellerName?: string;
  sellerPhoneNumber?: string;
  description?: string;
  coverImageUrl: string;
  imageUrls?: string[];
  subcategoryId: number;
  adType: AdType;
  price: number;
  token: string;
  propertyType: string; //todo
  propertyCondition?: string;
  roomsCount: number;
  squareMeter: number;
  originalListingId: string;
  originalListingUrl: string;
  tags: string[];
  originalSlimPartialData?: RawYad2ListingSlimPartialData;
  originalFullData?: RawYad2ListingFullItemData;
};

export type Yad2Customer = {
  name: string;
  agencyName?: string;
  email?: string;
  id: number;
  phone: string;
  isVirtualPhoneNumber: boolean;
  brokerPhone: string;
};

export type Yad2Address = {
  city: {
    text: string;
  };
  neighborhood: {
    text: string;
  };
  street: {
    text: string;
  };
  house: {
    number: number;
    floor: number;
  };
  coords: {
    lat: number;
    lon: number;
  };
};
export type RawYad2ListingSlimPartialData = Partial<BaseDocument> & {
  address: Partial<Yad2Address>;
  customer: Partial<Yad2Customer>;
  metaData: {
    coverImage: string;
  };
  subcategoryId: number;
  adType: AdType;
  price: number;
  token: string;
  additionalDetails: {
    property: {
      text: string;
    };
    roomsCount: number;
    squareMeter: number;
  };
  tags: { name: string }[];
  orderId: number;
  priority: number;
};

export const adTypes = ["commercial", "private"] as const;
export type AdType = typeof adTypes[number];

export type RawYad2ListingFullItemData = Partial<BaseDocument> & {
  token: string;
  orderId: number;
  adNumber: number;
  adType: AdType;
  categoryId: number;
  subcategoryId: number;
  priority: number;
  statusId: number;
  price: number;
  additionalDetails: {
    balconiesCount: number;
    entranceDate: string;
    squareMeter: number;
    roomsCount: number;
    propertyGroup: {
      id: number;
      text: string;
      textEng: string;
    };
    property: {
      id: number;
      text: string;
      textEng: string;
    };
    parkingSpacesCount: number;
    propertyCondition: {
      id: number;
      text: string;
    };
    squareMeterBuild: number;
    buildingTopFloor: number;
    isEnterDateFlexible: boolean;
  };
  inProperty: {
    isAssetExclusive: boolean;
    includeAirconditioner: boolean;
    includeBalcony: boolean;
    includeBars: boolean;
    includeBoiler: boolean;
    includeFurniture: boolean;
    includeElevator: boolean;
    includeParking: boolean;
    includeSecurityRoom: boolean;
    includeWarehouse: boolean;
    isHandicapped: boolean;
    includeRavBariach: boolean;
    includeTadiran: boolean;
    includeTornado: boolean;
    isImmediateEntrance: boolean;
    isRenovated: boolean;
    // TODO - more here?
  };
  searchText: string;
  customer: Yad2Customer;
  address: Yad2Address;
  metaData: {
    coverImage: string;
    images: string[];
    description: string;
  };
  dates: {
    updatedAt: string;
    createdAt: string;
    endsAt: string;
    rebouncedAt: string;
  };
  abovePrice: string;
  tags: {
    name: string;
    id: number;
    priority: number;
  }[];
  //...
};

export type SingleListingItemScrappingResponse = {
  props: {
    pageProps: {
      dehydratedState: {
        queries: [
          {
            queryKey: string[];
            state: {
              data: RawYad2ListingFullItemData;
            };
          }
        ];
      };
    };
  };
};

export type ListingsItemsPageScrappingResponse = {
  url: string;
  props: {
    pageProps: {
      searchApiQuery: {
        page: number;
      };
      feed: {
        private: RawYad2ListingSlimPartialData[];
        agency: RawYad2ListingSlimPartialData[];
        yad1: RawYad2ListingSlimPartialData[];
        platinum: RawYad2ListingSlimPartialData[];
        kingOfTheHar: any[];
        trio: any[];
        booster: any[];
        leadingBroker: any[];
        pagination: any;
      };
    };
  };
};

export type GenericScrappingIDE_Response = {
  url: string;
  stringRawData: string;
};

export type ScrappingJobState = {
  currentState: "idle" | "scrappingPages" | "scrappingSingleListings";
  currentCollectionId: string;
};

export type RealEstateCallParsedOpenAiResponse = {
  customerDetails: {
    callerFirstName: string;
    callerLastName: string;
    additionalNotes: string;
    customerBudget: number;
    neighborhood: string;
    street: string;
    roomsMin: number;
    roomsMax: number;
  };
  callSummary: string;
  customerSummary: string;
  speakerNames: {
    original: string;
    actual: string;
  }[];
};

export type CallTranscriptAndSummary = BaseDocument & {
  AI: RealEstateCallParsedOpenAiResponse;
  jobId: string;
  textTranscription: string;
  splitTranscription: {
    speaker: string;
    sentence: string;
  }[];
  summary: string;
};
