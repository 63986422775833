import numeral from "numeral";
import { firebase } from "../../firebaseConfig";
import { GenericContact, Room, rooms } from "./../../@types/sharedSchema";
import { ProcessedListingAddress } from "./../../@types/sharedSchema";

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function randomNumber(minimum, maximum) {
  return Math.round(Math.random() * (maximum - minimum) + minimum);
}

/**
 * Usage: getObjectPropertyByString(someObj, 'part3[0].name');
 */
export function getObjectPropertyByString(o: any, s: string) {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  var a = s.split(".");
  for (var i = 0, n = a.length; i < n; ++i) {
    var k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

// give a file that represents a video, return a blob that represents the video cover
export function getVideoCover(file, seekTo = 0.0, isUrl = false) {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", isUrl ? file : URL.createObjectURL(file));
    videoPlayer.setAttribute("crossorigin", "anonymous");
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file");
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
}

export function getRandomId(length = 10) {
  let result = "";
  let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getYoutubeLink = (youtubeCode: string) => {
  return "https://www.youtube.com/embed/" + youtubeCode;
};

export async function hashIdentifiedFirestorePromises(hash = {}) {
  const promises = Object.keys(hash).map(async (key) => ({ [key]: await hash[key].get() }));
  const resolved = await Promise.all(promises);
  return resolved.reduce((hash, part) => ({ ...hash, ...part }), {});
}

export async function hashIdentifiedFirestorePromisesWithArray(hash = {}) {
  const promises = Object.keys(hash).map(async (key) => ({
    [key]: await Promise.all(
      hash[key].map((p: firebase.firestore.Query<firebase.firestore.DocumentData>) => p.get())
    )
  }));
  const resolved = await Promise.all(promises);

  return resolved.reduce((hash, part) => ({ ...hash, ...part }), {}) as Record<
    string,
    firebase.firestore.QuerySnapshot[]
  >;
}

export function formatNumber(num: number) {
  return numeral(num).format("0,0");
}

const SHORT_NUMBER_FORMATTER = new Intl.NumberFormat("en-US", {
  notation: "compact"
});

export function formatShortNumber(numberValue: number) {
  return SHORT_NUMBER_FORMATTER.format(numberValue);
}

export function formatShortCurrency(numberValue: number, currencyCode: string = "₪") {
  return `${formatShortNumber(numberValue)}${currencyCode}`;
}

export const getFullName = (firstName?: string, lastName?: string) => {
  if (!firstName && !lastName) return "";
  return `${firstName ?? ""} ${lastName ?? ""}`.trim();
};

export const getUserFullName = (user?: GenericContact) => {
  if (!user) return "";
  const { callerFirstName, callerLastName } = user;
  return getFullName(callerFirstName, callerLastName);
};

export const getListingAddressString = (address: ProcessedListingAddress) => {
  if (!address) return "";
  const { city, neighborhood, street } = address;
  return [street, neighborhood, city].filter(Boolean).join(", ").trim();
};

export const getMinMaxRoomsString = (minRooms: Room, maxRooms: Room) => {
  if (!minRooms && !maxRooms) return "";
  if (!minRooms) return "עד " + maxRooms;
  return maxRooms === rooms[rooms.length - 1]
    ? `${minRooms}+`
    : minRooms === maxRooms
    ? minRooms
    : [minRooms, maxRooms].join(" - ");
};
